import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import InstarVisionCard from 'components/Software/macOS/InstarVision/Webserver/instarVisionCard01';
import InstarVisionCards from 'components/Software/macOS/InstarVision/Webserver/instarVisionCards01';
import PrimaryBox from 'components/Software/macOS/InstarVision/Webserver/PrimaryBox';
import UserTable from 'components/Software/macOS/InstarVision/Webserver/userTable';
import NavButtons from 'components/Software/macOS/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for macOS",
  "path": "/Software/macOS/InstarVision/Webserver/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "If you want to broadcast your camera´s video on your website, simply use the Webserver menu to create the necessary HTML code.",
  "image": "../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='InstarVision v3 for macOS' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/macOS/InstarVision/Webserver/' locationFR='/fr/Software/macOS/InstarVision/Webserver/' crumbLabel="Webserver" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/29114/Banner_en-InstarVision2-0_Webserver.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAiElEQVQI14XHsQrCMBhF4f9V9Pkc3BS7O7TBpeIg6FJfxcXRChFcXYpbkpqktR6hk4LohY/DFboI3kFwEGu4G/AWHgGCBZ78W57nTGcJtB5RpUUdDepUo87tm6ZvphsyHb9KdWRx6Ujmisl4RFpaRDYVsr4i2xtSGGRnkcJ+9ifHcLlnsDr0/wXDZ8DCgWAM0gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/e4706/Banner_en-InstarVision2-0_Webserver.avif 230w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/d1af7/Banner_en-InstarVision2-0_Webserver.avif 460w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/7f308/Banner_en-InstarVision2-0_Webserver.avif 920w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/e1c99/Banner_en-InstarVision2-0_Webserver.avif 1380w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/76ea5/Banner_en-InstarVision2-0_Webserver.avif 1840w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/f696c/Banner_en-InstarVision2-0_Webserver.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/a0b58/Banner_en-InstarVision2-0_Webserver.webp 230w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/bc10c/Banner_en-InstarVision2-0_Webserver.webp 460w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/966d8/Banner_en-InstarVision2-0_Webserver.webp 920w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/445df/Banner_en-InstarVision2-0_Webserver.webp 1380w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/78de1/Banner_en-InstarVision2-0_Webserver.webp 1840w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/882b9/Banner_en-InstarVision2-0_Webserver.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/81c8e/Banner_en-InstarVision2-0_Webserver.png 230w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/08a84/Banner_en-InstarVision2-0_Webserver.png 460w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/c0255/Banner_en-InstarVision2-0_Webserver.png 920w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/b1001/Banner_en-InstarVision2-0_Webserver.png 1380w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/161ec/Banner_en-InstarVision2-0_Webserver.png 1840w", "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/29114/Banner_en-InstarVision2-0_Webserver.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aa22529c3b8f4aab1b717dfb7a7d7b0a/c0255/Banner_en-InstarVision2-0_Webserver.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "webserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#webserver",
        "aria-label": "webserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Webserver`}</h2>
    <p><strong parentName="p">{`If you want to broadcast your camera´s video on your website, simply use the Webserver menu to create the necessary HTML code.`}</strong></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InstarVisionCard mdxType="InstarVisionCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The default web server port is 80 - please change it to something above 8080 in case the web user interface does not show (see below). The web server port has to be added to the IP address, if it is not 80 - e.g. `}<code><a parentName="p" {...{
          "href": "http://192.168.1.18:8081"
        }}>{`http://192.168.1.18:8081`}</a></code>{` or `}<code>{`http://localhost:8081`}</code>{` ! The default login admin/instar can be changed in the `}<a parentName="p" {...{
        "href": "/en/Software/macOS/InstarVision/Users/"
      }}>{`User Menu`}</a>{`.`}</p>
    <p>{`To access the web user interface from the internet, first click on the Internet IP button to resolve your WAN IP address, then forward a port inside your router to the IP address of your InstarVision Server. You will be able to access the interface from the internet by using your internet IP followed by the forwarded port that you choose in the `}<a parentName="p" {...{
        "href": "/en/Software/macOS/InstarVision/System/"
      }}>{`System settings`}</a>{`. The default web server port is set to be 80. Please go to the System Settings to change the web port to the port that you forwarded inside your router´s NAT firewall. We recommend to use ports that are higher than 8080. The Internet Address of your internet access is most likely to change every 24h - please use the DDNS address of your INSTAR IP camera to access the software instead (see below).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <UserTable mdxType="UserTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "choose-the-right-video-plugin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#choose-the-right-video-plugin",
        "aria-label": "choose the right video plugin permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Choose the right Video Plugin`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <InstarVisionCards mdxType="InstarVisionCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <h3 {...{
      "id": "give-me-an-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#give-me-an-example",
        "aria-label": "give me an example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Give me an Example`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/2bef9/InstarVision2_Webserver_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACqklEQVQozwGfAmD9ACkzLQgYFhYjGxIiGhIiHB8mG1BDNDY6NkNFQT49PDY0MzgwKyccEyEZFR8ZFSIbGRcYGBsYF5eWl7u6ugAhKiEEFQo+OSVhTDMKIxUdIguTfGnDw8bS0tLU1dTNzs5zaWMmEQAzHw0qGgwrHBUcGSYNCA17enqlpKQAFiciNC0cSz4oeU0vJDIgNjQbs5aF7+7w7+/v+/v7////jX5vLRUAOyUSMyIVMSEhIxcqCwcFrq2u4+PjABsrI1s8I2A/JC43JkA/J1NEKrqhlebm5+np6vT09PHy83NsZxoPA0MvGl9CLDkyMxAeKhUOB87MyPv7/AAkLiQeIA1uQh1WTDSFY06EX0uxp6Lx8PDr6unq6Oj29fd6dm0oGgRkSSengVGJZ1A8JyYoHxHAwL3v8fQAICMfAAgGIhoMNiQTVj82TTk0f3548/X25OXl5ufn////d3dyGBIHQjUkZlVAZ1ZFLSIaGBUOxsfG/f39ABcaGAYNCwkYFQcZFgcaFwAIBGlqZ+zg4ebb2ujf3+7m6Xp0ciYkITYzMR8fHSIgHTAsJjo0LdfX1v///wAZJiUNISEYNTMgNjUtRUIFKippaGjv0tLq0M7k0dfZzt6ZhYE+OjRTQzdGPDQ4Mi0tKSc/NzDW1dP///8AISopGy0rGDs9RFFKWl1TGy8tdHt57+zr5uTk6OXl+fb2h4WFQT88TUQ+gnhxTkdAHyAdFxoX1dbV////ACcqJSErJBItLTtKQoR2ZTpCOXF+e/b19O7u7/f3+Pv+/5qZknp0XzQ3LDEvImJTQCIlIlBdTeLl4v///wAqLSYTHRkSKSgaMzE+S0UWLiw7TEyChYJ7fHuDg4Oxp6GXiHdSUUNLUkk1Ny9EOS9QVE5aZFmgn6DV1dSn4wgPbqV17AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/e4706/InstarVision2_Webserver_01.avif 230w", "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/d1af7/InstarVision2_Webserver_01.avif 460w", "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/7f308/InstarVision2_Webserver_01.avif 920w", "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/e3723/InstarVision2_Webserver_01.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/a0b58/InstarVision2_Webserver_01.webp 230w", "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/bc10c/InstarVision2_Webserver_01.webp 460w", "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/966d8/InstarVision2_Webserver_01.webp 920w", "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/a9a89/InstarVision2_Webserver_01.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/81c8e/InstarVision2_Webserver_01.png 230w", "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/08a84/InstarVision2_Webserver_01.png 460w", "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/c0255/InstarVision2_Webserver_01.png 920w", "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/2bef9/InstarVision2_Webserver_01.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fe7a8d3cbe2e0bf573d72cb1e76a2f8d/c0255/InstarVision2_Webserver_01.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`1`}</strong>{` - Go to the `}<a parentName="p" {...{
        "href": "/en/Software/macOS/InstarVision/System/"
      }}>{`System Menu`}</a>{` and change the Webserver Port to a port higher than 8080. You can also deactivate the required login here.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0aacab1635292d9324e70528a4784951/2bef9/InstarVision2_Webserver_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACF0lEQVQoz42Ry08TURSH578xumNtwqobwRelJTGGwMLMdNqZPozJQGuxhEAwNoWWBUEXGIiGldL0ERNrLQnWtEUSVkppaGEeYkNtp9NSOzP3HtOOmrgxftub3znf71xixHzLRZEumiJpknTY7A7aydKU3eZ0OliWtdM2h41kaIqmSIoi7bTNMmo2mUxra08TiQSxs/8JdnfRwQEAAELQR1XVRkPudDoYY/gbVdVOK5VnoVB0c4PIH36BoSGdYXovuo4xRgh1uz+aSktWLtoXHaT/4s+glqZ93o6d7WSJfPEQJieR32+E4d9gDBjLAJWR2/XhG8S+xIPnPlpeMow/5PN3x8cYj/Me65igyDsT4zfHrNctZtPwtXTmvVGtCSBaRsFqJQpHR7C1hZJJY2ksGb985dLVwcEVhkmHw9FgcHt29kUgEAqHP2azhp0McOp2AccRuVyuP64HALxNp15HX0nS11a1CoqC0+lel25X03VBEAxzBUBYXIRIhMjv7fXCmmaE37xLpfp6dV2vZbPfBwZqolhvt2vn5+Vy2SjelOXKyQnCmMgVCn0XHfWP2Wg0SqWSIAiCJInFopjJCMfHoiTxPF+v142w0lK+Vc8wwkTB2Pz7h/+HrqryAt9SFIJlae6hzx14xM3PcQvznpkZt8/3wMu5Pe6p6en4y/XY5urjhUAw9MTrnZoL+J9vrEdWI8tLy4l4/CdSuwmPyuWuhgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0aacab1635292d9324e70528a4784951/e4706/InstarVision2_Webserver_02.avif 230w", "/en/static/0aacab1635292d9324e70528a4784951/d1af7/InstarVision2_Webserver_02.avif 460w", "/en/static/0aacab1635292d9324e70528a4784951/7f308/InstarVision2_Webserver_02.avif 920w", "/en/static/0aacab1635292d9324e70528a4784951/e3723/InstarVision2_Webserver_02.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0aacab1635292d9324e70528a4784951/a0b58/InstarVision2_Webserver_02.webp 230w", "/en/static/0aacab1635292d9324e70528a4784951/bc10c/InstarVision2_Webserver_02.webp 460w", "/en/static/0aacab1635292d9324e70528a4784951/966d8/InstarVision2_Webserver_02.webp 920w", "/en/static/0aacab1635292d9324e70528a4784951/a9a89/InstarVision2_Webserver_02.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0aacab1635292d9324e70528a4784951/81c8e/InstarVision2_Webserver_02.png 230w", "/en/static/0aacab1635292d9324e70528a4784951/08a84/InstarVision2_Webserver_02.png 460w", "/en/static/0aacab1635292d9324e70528a4784951/c0255/InstarVision2_Webserver_02.png 920w", "/en/static/0aacab1635292d9324e70528a4784951/2bef9/InstarVision2_Webserver_02.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0aacab1635292d9324e70528a4784951/c0255/InstarVision2_Webserver_02.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`2`}</strong>{` - Open the Webserver Menu to check your local IP address and type it into your web browser, followed by the Webserver Port. The default login is `}<strong parentName="p">{`admin / instar`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/2bef9/InstarVision2_Webserver_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABbklEQVQoz52Qy07CUBRF+VUZdkhwqAkTnZKWK6Gve7mVRwBjC4kaKgM1SGIiiA2FFsqrLYUSEeIPaAFBAxNd2bOzV87J8QWDhwAAkiQpiqJI8hzjXCaTSqXykiSJ4iZ5SbrI5QAAyxYZCAQO/H4fxhghFF+CICzfPbRbmqaqi7fZ3J3OLduLO/14nzumlUwmV+VEIsEwjI/7TakoP5Ufa9WaMzQ7mm5oelfTzV5/bI96HQNjzLIsx3E8z0ciER//DUvTgigOYrRxdFzV9cnQatRflZd6Q1F0tTm27IHRFQRhZSKEIhS1lTmaFgoF++TUJIjnTmcyNDW12Wqo7WbL0PXJcrOA1zKEEICzrQxZNpZOV0KhCkHcK8rCdacjZ+qMV5lNXLPX32xeymAre5cjlI1GM+Fw9lIsybJcLG5yK8s3V9cIofWmXXnle2EYegeGYbZn7pUhz0OO84b72NS8h/389p9Yy/BfxOPxr7M/AVububoH0OCRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/e4706/InstarVision2_Webserver_03.avif 230w", "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/d1af7/InstarVision2_Webserver_03.avif 460w", "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/7f308/InstarVision2_Webserver_03.avif 920w", "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/e3723/InstarVision2_Webserver_03.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/a0b58/InstarVision2_Webserver_03.webp 230w", "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/bc10c/InstarVision2_Webserver_03.webp 460w", "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/966d8/InstarVision2_Webserver_03.webp 920w", "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/a9a89/InstarVision2_Webserver_03.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/81c8e/InstarVision2_Webserver_03.png 230w", "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/08a84/InstarVision2_Webserver_03.png 460w", "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/c0255/InstarVision2_Webserver_03.png 920w", "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/2bef9/InstarVision2_Webserver_03.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/993e33ecc0ccc2ac00e18fb64f1bd23a/c0255/InstarVision2_Webserver_03.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`3`}</strong>{` - Wait for the web user interface to load and choose the Simple JPEG Viewer - this is the only mode that does not require a browser plugin to display the video.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6888b8adfb447cfa5e0579091693e4a3/2bef9/InstarVision2_Webserver_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABtUlEQVQoz52RTUsbURSGRwQbIUZtRKPSJpUaFY1G0aBGQcFV112LooIfcaEJGJOMIQoKSpMxM+kiOXfuvZM7051EcOEHWOhCcKEgKlU3gtDizxAnRiW48lm/z+F9OZzP55MkSdCJx+NMUTJbW9Hoj5B/IcIHl/kgHwjwwUAo4F+OhOM55r3ekdFRTlEUQgjVwRj/Ofx9/+//wtxsWSFXZy7+UmGwlBqsZqPJWOJo7aB6khCiaRoAcAghWZZRjv29vYvzi3nPpMPywV1vdtcZnZ9Nnbbyhiqru2sQ4DEDABhjURQ5OQcgpCrp66Pju7NL79S4vZzrspW0fzK67ZU9zV9rLfaONhdAUsYYIUQIkaTXMoDK2N+T09urG//0lKmwoNFq7nPUeL41eb4PtDQ6u7t7EaSyMsY4kfj5ImfLZ7a3dw8OZsaHS4s4W7VhsO3j6kT/TmxsZKB9yOVE8tNGXU7ky4wxTfslbsaWQouRMB9dC7OkkNFSSXFjfXXlOfiGnPUBgFCqqipjKk0zGVNAGBNFSaefY/pmKV9+dSIFAAhARpD9BgDky/hdUEofayffBQAIgvAAO1RhjqQ7UW4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6888b8adfb447cfa5e0579091693e4a3/e4706/InstarVision2_Webserver_04.avif 230w", "/en/static/6888b8adfb447cfa5e0579091693e4a3/d1af7/InstarVision2_Webserver_04.avif 460w", "/en/static/6888b8adfb447cfa5e0579091693e4a3/7f308/InstarVision2_Webserver_04.avif 920w", "/en/static/6888b8adfb447cfa5e0579091693e4a3/e3723/InstarVision2_Webserver_04.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6888b8adfb447cfa5e0579091693e4a3/a0b58/InstarVision2_Webserver_04.webp 230w", "/en/static/6888b8adfb447cfa5e0579091693e4a3/bc10c/InstarVision2_Webserver_04.webp 460w", "/en/static/6888b8adfb447cfa5e0579091693e4a3/966d8/InstarVision2_Webserver_04.webp 920w", "/en/static/6888b8adfb447cfa5e0579091693e4a3/a9a89/InstarVision2_Webserver_04.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6888b8adfb447cfa5e0579091693e4a3/81c8e/InstarVision2_Webserver_04.png 230w", "/en/static/6888b8adfb447cfa5e0579091693e4a3/08a84/InstarVision2_Webserver_04.png 460w", "/en/static/6888b8adfb447cfa5e0579091693e4a3/c0255/InstarVision2_Webserver_04.png 920w", "/en/static/6888b8adfb447cfa5e0579091693e4a3/2bef9/InstarVision2_Webserver_04.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6888b8adfb447cfa5e0579091693e4a3/c0255/InstarVision2_Webserver_04.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`4`}</strong>{` - Use the drop-down menu to switch between your camera channels. And the PTZ control pad to pan & tilt your camera (if supported by your model).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f46968bb8680492ba83c5813340c8891/2bef9/InstarVision2_Webserver_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABi0lEQVQoz3WR3U7CMBiGuQ2vwxOuw9vgCvSUI45MPHDttq/t1jZEoy78ZD8yIRINKCL4k5iYiCiwTUDUOzA6UVz0zdMe9cnb9kttaFRwk3MupZSmaXNed91mo3nabFb2vYNKJabq+57jaJqGEMIYq6q6ub6eooRAHEI0Va2Vyjed7vXl1ct4MlvgdfocPA6klEAIpZQxhjBOkYVQSuuH9dbJabd9Pg7CcDCMhqOY8Sjs390Jzj9bPk4qivIjA4Dg4qp72W61z1tn0zCKhqOnURAzCaKHXu9fWQddcNFpX8Tyn83SMEDXYxkh9OvaAFAsFHd3dhpHx2+T6SQIp2EUM4vGw37fzOf1efMfspRSCLG9tbXvOK5tf+PZdslxOisr27mcxjklJCmT+c/ruq4mgrHC2P3yspXNYiEYIb/eHIcxyj4nsRjjYxkmwOvSUjWTQfk8A0jKAIAwRigBUhSEFISA3KbTxbVVVcpkMwAxGPXsPd+1Kq71tXuW7xVqftn3rLJT9kqOZRVgPqp3njPBpsHo9dwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f46968bb8680492ba83c5813340c8891/e4706/InstarVision2_Webserver_05.avif 230w", "/en/static/f46968bb8680492ba83c5813340c8891/d1af7/InstarVision2_Webserver_05.avif 460w", "/en/static/f46968bb8680492ba83c5813340c8891/7f308/InstarVision2_Webserver_05.avif 920w", "/en/static/f46968bb8680492ba83c5813340c8891/e3723/InstarVision2_Webserver_05.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f46968bb8680492ba83c5813340c8891/a0b58/InstarVision2_Webserver_05.webp 230w", "/en/static/f46968bb8680492ba83c5813340c8891/bc10c/InstarVision2_Webserver_05.webp 460w", "/en/static/f46968bb8680492ba83c5813340c8891/966d8/InstarVision2_Webserver_05.webp 920w", "/en/static/f46968bb8680492ba83c5813340c8891/a9a89/InstarVision2_Webserver_05.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f46968bb8680492ba83c5813340c8891/81c8e/InstarVision2_Webserver_05.png 230w", "/en/static/f46968bb8680492ba83c5813340c8891/08a84/InstarVision2_Webserver_05.png 460w", "/en/static/f46968bb8680492ba83c5813340c8891/c0255/InstarVision2_Webserver_05.png 920w", "/en/static/f46968bb8680492ba83c5813340c8891/2bef9/InstarVision2_Webserver_05.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f46968bb8680492ba83c5813340c8891/c0255/InstarVision2_Webserver_05.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`5`}</strong>{` - In Internet Explorer choose the ActiveX Viewer and install the ActiveX plugin. Please make sure the plugin is not blocked by your Antivirus/Firewall.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/452cfa82b8f59cccb53f1eebea416238/2bef9/InstarVision2_Webserver_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABdUlEQVQoz2OYPGfh0iWLlyxZsmzZsqVLlu3dvfH0qUOrV69pbmmtqq6uq62tAYPq6qqW5ubZMDBr9uz506czzJ83FwLmzZs3e9bsvbt2PXv0OKcgj1eYQ01NREiUS4CTRVmYlZeVWUNVY/asWfPnz4conjt/PsM8JDB/3rw9W7dePXO2tLTQ3VQ6O8zGzFRVkI/NXlMwyEbXRN9g9uyZcM3z5s1DaJ47b96iuXO3bN51/MzV3OwcYSE2e3MDSVkxISEePTXpQAc9V1vzmUg2o2qeO3fxwoWnDh66e+lyYWGhiI62rpM9m7iYgoJcsJ+nnaWRs5MdXBu6Zoj+9evX79yxMyk1WUhBxtTOgkWAT0lB2tfLzkBX2d3LZRYumyGalyxZsnz58kmTJtbU1lbX1dfV1bU2NzU1NtbV1Xd3deGzGaJ/zpw5CxcuXLZ06bKlILAEDJYuXbpo0SI0xeiakY3ABOiaIaJo7iESMCwDgwULFpChGQBajGUF9VDgRgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/452cfa82b8f59cccb53f1eebea416238/e4706/InstarVision2_Webserver_06.avif 230w", "/en/static/452cfa82b8f59cccb53f1eebea416238/d1af7/InstarVision2_Webserver_06.avif 460w", "/en/static/452cfa82b8f59cccb53f1eebea416238/7f308/InstarVision2_Webserver_06.avif 920w", "/en/static/452cfa82b8f59cccb53f1eebea416238/e3723/InstarVision2_Webserver_06.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/452cfa82b8f59cccb53f1eebea416238/a0b58/InstarVision2_Webserver_06.webp 230w", "/en/static/452cfa82b8f59cccb53f1eebea416238/bc10c/InstarVision2_Webserver_06.webp 460w", "/en/static/452cfa82b8f59cccb53f1eebea416238/966d8/InstarVision2_Webserver_06.webp 920w", "/en/static/452cfa82b8f59cccb53f1eebea416238/a9a89/InstarVision2_Webserver_06.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/452cfa82b8f59cccb53f1eebea416238/81c8e/InstarVision2_Webserver_06.png 230w", "/en/static/452cfa82b8f59cccb53f1eebea416238/08a84/InstarVision2_Webserver_06.png 460w", "/en/static/452cfa82b8f59cccb53f1eebea416238/c0255/InstarVision2_Webserver_06.png 920w", "/en/static/452cfa82b8f59cccb53f1eebea416238/2bef9/InstarVision2_Webserver_06.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/452cfa82b8f59cccb53f1eebea416238/c0255/InstarVision2_Webserver_06.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`6`}</strong>{` - The ActiveX Viewer can display up to 32 cameras at the same time. Click on the Setup button to further configure the plugin to your needs.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ab4c11f8c142f4611583a32163a1b0d1/2bef9/InstarVision2_Webserver_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACqElEQVQoz23L308ScQAA8HOzRPSEToE7Do7je/z+oaBgIkplRpo/QkUjiEhX6qrpZrVW69cDtH69NOsfKFtzS1+yaG212Wpt+iDLACGXeom5VaJoVOq81nuf9w+kNRvYQpRP4QgQIgAnVWK7WSoEQgyIJLhAiiIyYYGa4JEYT4LxAI4JUAxHBf3994LPnkOkhszksW0WeUejmQ14Cp3AZ8VzxAUQwkFgFshnG4h8ozi3mIB1eJ5OzMMxBcyCbwT8L0ZGIL4SmPSiOpvqbK0GV/PLy8heu4qLcyCYxc/NoGDIQmwrFmaaRFmFIo6BJIFAk8fa/vLdGP2HgXAt5amkfDbgOtJ0pq/r2sWeR/5zp0+1N3nd7R6n19V61N3qcbV1dXgC3b69plJYrBYi7IHBx6Nv3kOyQpkEcE5aea+eDv5IpuLx6VA4Go/F4rHYh8nwVCweiU7Nzs6tpFK/0um753usMlIDCt6Ovk7fugkBBQXBOQJORvDJwO+NTZqem/9C0zQ9R9OJRGJ5eTm5tLS2tra+vs4wjP9Cj0qEYHxuKDTBDA9DAiGazYWhXPbg0BDDMBubm8z/bG1tMQxzqM2pBKKK0qJI+COTWoUwhTyPorg67f0HDxe+fY9MT3+amf08MzMxERofH1tIJFKrqa+LiwuJ+ZVk8oTPI5cSeyzGSHiSYRhIqlHjei1qrahzt3R2ea019pLKcomxWKJRASDV67U7zWaHo7GpofZ24Gpz3T5CIkJxNBqL/stKjVyqlSF6vaVm95W+Y1UHqmBAZqGCTJidnc3CSfxgS7X/crehRFmkl7Q324ACOLz10Xg0/TMNafSKfDGGqFW4ybir3k6YSzgKOUYRO3iIFIDeDtedS52tzv31LbWlZYbq8iKHs8F9/PD1wPVgMPgXhNsJzbHHd9MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ab4c11f8c142f4611583a32163a1b0d1/e4706/InstarVision2_Webserver_07.avif 230w", "/en/static/ab4c11f8c142f4611583a32163a1b0d1/d1af7/InstarVision2_Webserver_07.avif 460w", "/en/static/ab4c11f8c142f4611583a32163a1b0d1/7f308/InstarVision2_Webserver_07.avif 920w", "/en/static/ab4c11f8c142f4611583a32163a1b0d1/e3723/InstarVision2_Webserver_07.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ab4c11f8c142f4611583a32163a1b0d1/a0b58/InstarVision2_Webserver_07.webp 230w", "/en/static/ab4c11f8c142f4611583a32163a1b0d1/bc10c/InstarVision2_Webserver_07.webp 460w", "/en/static/ab4c11f8c142f4611583a32163a1b0d1/966d8/InstarVision2_Webserver_07.webp 920w", "/en/static/ab4c11f8c142f4611583a32163a1b0d1/a9a89/InstarVision2_Webserver_07.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ab4c11f8c142f4611583a32163a1b0d1/81c8e/InstarVision2_Webserver_07.png 230w", "/en/static/ab4c11f8c142f4611583a32163a1b0d1/08a84/InstarVision2_Webserver_07.png 460w", "/en/static/ab4c11f8c142f4611583a32163a1b0d1/c0255/InstarVision2_Webserver_07.png 920w", "/en/static/ab4c11f8c142f4611583a32163a1b0d1/2bef9/InstarVision2_Webserver_07.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ab4c11f8c142f4611583a32163a1b0d1/c0255/InstarVision2_Webserver_07.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`7`}</strong>{` - To embed the web broadcast in your website, choose your Viewer in the Webserver Menu. The template files will be saved in your `}<a parentName="p" {...{
        "href": "/en/Software/macOS/InstarVision/Record/General/"
      }}>{`REC Directory`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/066ebda01c3b28fd24112e3bff1555a1/2bef9/InstarVision2_Webserver_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABu0lEQVQoz42Pz0/aYByH38V0bZgo4o+Wt7+hSKvAostuOy6us1pbKKNFBBXmuOxk4sWb2cUdTbZE9x8umRZGpNDSdxE9GE2TPXkO38PnOXyBbtjlStN2Wo5zUG82LGdv27AMs2SYhq6b7zd2Pnw0Va2sapa6aW2opYmmZnzSNQPMk7ycTiu8yElrYnaNzhfYjKDwXIYVeMjFliCRpLE4hU3Dl9M0wBcBtvQCIwG2iMchYIXV/OuisFJgcnkx95aTi6LyRlTWhdw6KxfpggKzq5Qok9IyJeWorEyKMsmszC5IFK2ARFIgOWmGzqYgm4FMBrIUxREJ5lWcjCVS+EIKn4VEkiESE5MMMc/gM/QUDuNzPNBKzZpzWK217Vq7Vj+qNzpO/fNu88tuo1Ou7FeqLdNqbOnVLd1+7KZuV7Yt8Lv7dzT0hp7neYPRaBj4/sAbIIS+X/1SD1vawf7F5U/PG1xf/3Hdm8d2XRcghMI7UPhwhL7vI4Q67WohHVsWiNPjNkIoCAL0DDAOnxJM4vOzk6Pyu29f935cnN/Hz4YhQBHc9Hr9nju8ve32+1GbyPh/iIzDMByPH36Kiv8Bun6IPguwvpcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/066ebda01c3b28fd24112e3bff1555a1/e4706/InstarVision2_Webserver_08.avif 230w", "/en/static/066ebda01c3b28fd24112e3bff1555a1/d1af7/InstarVision2_Webserver_08.avif 460w", "/en/static/066ebda01c3b28fd24112e3bff1555a1/7f308/InstarVision2_Webserver_08.avif 920w", "/en/static/066ebda01c3b28fd24112e3bff1555a1/e3723/InstarVision2_Webserver_08.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/066ebda01c3b28fd24112e3bff1555a1/a0b58/InstarVision2_Webserver_08.webp 230w", "/en/static/066ebda01c3b28fd24112e3bff1555a1/bc10c/InstarVision2_Webserver_08.webp 460w", "/en/static/066ebda01c3b28fd24112e3bff1555a1/966d8/InstarVision2_Webserver_08.webp 920w", "/en/static/066ebda01c3b28fd24112e3bff1555a1/a9a89/InstarVision2_Webserver_08.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/066ebda01c3b28fd24112e3bff1555a1/81c8e/InstarVision2_Webserver_08.png 230w", "/en/static/066ebda01c3b28fd24112e3bff1555a1/08a84/InstarVision2_Webserver_08.png 460w", "/en/static/066ebda01c3b28fd24112e3bff1555a1/c0255/InstarVision2_Webserver_08.png 920w", "/en/static/066ebda01c3b28fd24112e3bff1555a1/2bef9/InstarVision2_Webserver_08.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/066ebda01c3b28fd24112e3bff1555a1/c0255/InstarVision2_Webserver_08.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`8`}</strong>{` - The templates will contain the local IP of your server - please swap this for your DDNS address to access your server over the internet!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      